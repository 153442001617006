import UnisatImg from "../../assets/unisat.png";
import store from "../../store";
import {useEffect, useRef, useState} from "react";
import {saveAccount, saveType, saveLoading} from "../../store/reducer.js";
import {enqueueSnackbar} from "notistack";

export default function Unisat({handleClose}){
    // const {  notification } = App.useApp();
    const [disable,setdisable] = useState(false);
    const {unisat} = window;
    const selfRef = useRef ({
        accounts: [],
    });
    const self = selfRef.current;

    // useLogin(handleClose,"unisat");


    useEffect(() => {
        const checkUnisat =  () => {
            if (!unisat) {
                setdisable(true)
                return;
            }
            setdisable(false)
        }
        checkUnisat();
    }, []);


    const connect = async() =>{
        store.dispatch(saveLoading(true));
        try{
            if(disable){
                throw new Error("Unisat Wallet is not installed!")

            }
            await unisat.switchNetwork(import.meta.env.VITE_CHAIN);

            const result = await unisat.requestAccounts();
            self.accounts = result;
            if (result.length > 0) {
                // const signature = await unisat.signMessage(SIG_WORD);
                handleClose()
                store.dispatch(saveAccount(result[0]));
                store.dispatch(saveType('unisat'));
            }

        }catch (e) {
            console.error(e?.response?.data?.message || e.message || e.reason || e)
            enqueueSnackbar( (e?.response?.data?.message || e.message || e.reason || e), {variant: "error"})

        }finally {
            store.dispatch(saveLoading(false));

        }
    }

    return <dl  onClick={()=>connect()}>
        <dt>
            <img src={UnisatImg} alt="" />
        </dt>
    <dd className='text-black font-Montserrat'>Unisat</dd>
    </dl>
}
