import styled from "styled-components";
import DiscordImg from "../../assets/img/discord.svg";
import TwitterImg from "../../assets/img/twitter.svg";
import TelegramImg from "../../assets/img/telegram_b.svg";
import Version from "./version.jsx";

const FooterBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  padding: 30px 0;
  position: relative;
  z-index: 999;

  .version {
    font-size: 10px;
    opacity: 0.6;
  }
`;
const FirstLine = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  li {
    width: 48px;
    height: 48px;
    //border: 1px solid rgba(255,255,255,0.1);
    //background: rgba(255,255,255,0.1);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 30px;
      aspect-ratio: auto 30 / 30;
      height: 30px;
    }
  }
`;
const LastLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 30px;
  opacity: 0.8;
  font-size: 12px;
  color: #fff;
  a {
    text-decoration: none;
  }
`;
const Copyright = styled.div`
  opacity: 0.8;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
`;

export default function Footer() {
  return (
    <FooterBox>
      <FirstLine>
        {/* <li>
                <img src={GithubImg} alt=""/>
            </li> */}
        {/* <li>
                <img src={GitBook} alt=""/>
            </li> */}
        <a
          href="https://discord.gg/qtayMc5Yra"
          target="_blank"
          rel="noreferrer"
        >
          <li>
            <img src={DiscordImg} alt="" />
          </li>
        </a>
        <a href="https://x.com/MarketDobby" target="_blank">
          <li>
            <img src={TwitterImg} alt="" />
          </li>
        </a>
        <a href="https://t.me/dobbyclub" target="_blank">
          <li>
            <img src={TelegramImg} alt="" />
          </li>
        </a>
      </FirstLine>
      {/* <LastLine>
            <Link to="/privacy">Privacy Policy </Link>
            <Link to="/terms">Terms of Services </Link>
        </LastLine> */}
      <Copyright>@Dobby {new Date().getFullYear()}</Copyright>
      <div className="version">
        <Version />
      </div>
    </FooterBox>
  );
}
