import JoyidImg from "../../assets/Joyid.svg";
import {initConfig, requestAccounts, signMessage} from '@joyid/bitcoin';
import store from "../../store";
import {saveAccount, saveLoading, saveType} from "../../store/reducer";
import {enqueueSnackbar} from "notistack";
// import {App} from "antd";

export default function JoyidSEG({handleClose}){
    // const {  notification } = App.useApp();


    // useLogin(handleClose,"joyid","p2wpkh");

    const onConnect = async() =>{

        initConfig({
            name: "RGB++ Tx Accelerator",
            logo:`${window.location.origin}/images/newlogo.png`,
            requestAddressType:"p2wpkh",
            joyidAppURL: import.meta.env.VITE_CHAIN==="testnet"?"https://testnet.joyid.dev":"https://app.joy.id",
        });

        store.dispatch(saveLoading(true));
        try {


            const accounts = await requestAccounts();

            store.dispatch(saveAccount(accounts[0]));
            store.dispatch(saveType("joyid"));

            localStorage.setItem("joyidType","p2wpkh");
            handleClose()
        } catch (e) {
            console.error(e?.response?.data?.message || e.message || e.reason || e)
            enqueueSnackbar( (e?.response?.data?.message || e.message || e.reason || e), {variant: "error"})

        }finally {
            store.dispatch(saveLoading(false));
        }
    }

    return <dl onClick={() => onConnect()}>
        <dt>
            <img src={JoyidImg} alt=""/>
        </dt>
        <dd  className='text-black font-Montserrat'>JoyID (Native Segwit)</dd>
    </dl>
}
