import {createSlice} from '@reduxjs/toolkit';
import InitState from './initState';

const mainSlice = createSlice({
    name: 'main',
    initialState: InitState,
    reducers: {
        saveAccount(state, action) {
            state.account = action.payload;
        },
        saveLoading(state, action) {
            state.loading = action.payload;
        },
        saveConnectData(state, action) {
            state.connectData = action.payload;
        },
        saveJoyidInfo(state, action){
            state.joyidInfo = action.payload;
        },
        saveHash(state, action){
            state.didHash = action.payload;
        },
        saveType(state, action) {
            state.type = action.payload;
        },
        saveUtxo(state, action){
            state.utxo = action.payload;
        }

    },
});

export const {
    saveAccount,
    saveType,
    saveConnectData,
    saveUtxo,
    saveJoyidInfo,
    saveHash,
    saveLoading
} = mainSlice.actions;
export default mainSlice.reducer;
