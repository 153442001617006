import { createGlobalStyle } from "styled-components";
import "../assets/css/layout.css"
import "../assets/css/globals.css"

const GlobalStyle = createGlobalStyle`
  body,html{
      color: #FFF9F2;
        padding: 0;
      margin: 0;
      background: #090808;
    overscroll-behavior: none;
  }

 
  dl,dt,ul,li{
    padding: 0;
    margin: 0;
    
  }
  li {
    list-style: none;
  }
  * {
    padding: 0;
    margin: 0;
    &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
    &:focus{
      outline: none;
    }
    &:focus-visible{
      outline: none!important;
    }
  }
  a{
    text-decoration: none;
    color: #000;
  
  }
  .bgOrange{
       background: rgba(255, 149, 0, 0.1);;
  }
  .bgwhiteOp{
      background: #1d1d1d;
  }
  .btnPrimary{

      align-items: center;
      justify-content: center;
      background: linear-gradient( 180deg, #FFBD3D 0%, #FF9500 100%);
      padding:8px 10px;
      border-radius: 5px;
      color: #0D0C0B;
      cursor: pointer;
      display: flex;
      gap: 10px;
      width: 160px;
      font-size: 14px;
      &:disabled{
          opacity: 0.5;
          cursor: not-allowed;
      }
  }
  .acc{
      padding: 5px;
      width: 120px;
      margin-top: 10px;
  }
  .topBg{
      border-bottom: 1px solid rgba(255, 249, 242,0.2);
      backdrop-filter: blur(25px);
      -webkit-backdrop-filter: blur(25px);
      position: fixed;
      align-items: center;
      justify-content: space-between;
      width: 100vw;
      z-index: 99;
  }
  .btnTransfer{
      width: 100%;
      margin: 40px 0 20px;
  }
  .inner{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90vw;
      margin: 0 auto;
  }
  .bgBack2{
      background:#1b1b1b ; }
  .btm20{
      bottom: -60px;
  }
  a{
      color: #fff;
  }
  .menuMobile{
      width: 100vw;
      height: 100vh;
      position: fixed;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(8px);
      -webkit-backdrop-filter: blur(8px);
      z-index: 999999;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      li{
          line-height: 3em;
;
      }
      .active{
          position: relative;
          color: #ff9900;
          &:after{
              content: "";
              width:20px;
              height: 2px;
              background: #FF9500;
              position: absolute;
              left: 0;
              bottom: 0;
          }
      }
      
  }
 
  .disconnect{
      color: #ff9900;
      margin-top: 30px;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      padding-top: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
  }
`;

export default GlobalStyle;
