import {Route,Routes,Navigate} from "react-router-dom";
import Home from '../pages/home.jsx';
import LayoutOuter from "../components/layout/rightLayout.jsx";



function RouterLink() {
    return (
        <LayoutOuter>
        <Routes>
            <Route path="/" element={<Navigate to="/home" />}/>
            <Route path="/home" element={<Home />}/>


        </Routes>
        </LayoutOuter>
   );
}

export default RouterLink;
