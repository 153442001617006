import {useSelector} from "react-redux";
import {useEffect, useState} from "react";

import styled from "styled-components";
import {handleBalance} from "../utils/wallet.js";
import {enqueueSnackbar} from "notistack";


const Box = styled.div`
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: 10px;
    @media (max-width: 1274px) {
        gap:5px;
        font-size: 12px;
        margin-top: 20px;
    }
`

export default function Balance() {
    const account = useSelector(store => store.account);
    const type = useSelector(store => store.type);
    const [balance,setBalance] = useState({})
    const symbol = import.meta.env.VITE_CHAIN === 'testnet'?"tBTC" :"BTC"

    useEffect(() => {
        if(!account ) return;
        getBl()
        let timer = setInterval(() => {
            getBl()
        },20 * 1000 )
        return () =>{
            clearInterval(timer)
        }

    }, [account]);


    const getBl = async() =>{
        try{
            let rt = await handleBalance(type,account);
            setBalance(rt);

        }catch (e) {
            console.error("get balance",e)
            enqueueSnackbar(  "Get Balance Error"+e?.response?.data?.message || e.message || e.reason, {variant: "error"})

        }
    }

    return <Box>
        <div>
            Total:<span className="text-primary011 pl-1">{balance?.total}</span> <span
            className="sym">{symbol}</span>
        </div>
        <div>Confirmed:<span className="text-primary011 pl-1">{balance?.confirmed}</span> <span className="sym">{symbol}</span></div>
    </Box>
}
