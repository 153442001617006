import { useEffect, useState } from "react";
import styled from "styled-components";
import LogoImg from "../../assets/img/newlogo.png";
import { useSelector } from "react-redux";
import store from "../../store/index.js";
import {
  saveAccount,
  saveConnectData,
  saveJoyidInfo,
} from "../../store/reducer.js";
import ConnectModal from "../connectModal.jsx";
import { shortAddress } from "../../utils/global.js";
import { Delete, Wallet } from "lucide-react";
import Balance from "../balace.jsx";
import BtcImg from "../../assets/img/btc.png";
import Footer from "./footer.jsx";
import { Link2Off, X } from "lucide-react";

const RhtBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-right: 10px;

  height: 32px;
  border: 1px solid #ff9500;
  border-radius: 5px;

  font-size: 14px;
  color: #ff9500;
  cursor: pointer;
  width: 140px;
  box-sizing: border-box;
  background: #09080860;
  img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border-radius: 50%;
    object-position: center;
    object-fit: cover;
  }
`;
const LogoBox = styled.div`
  cursor: pointer;
  display: flex;
  align-content: center;
  padding: 15px 0;
  img {
    width: 152px;
  }
`;

const ContentBox = styled.div`
  width: 1274px;
  flex-grow: 1;
  margin: 65px auto 20px;
  @media (max-width: 1274px) {
    margin: 80px auto 20px;
    width: 100%;
  }
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  color: #fff;
  font-size: 30px;
  //font-family:'din_bold';
  font-family: din_bold;
  text-transform: uppercase;
  line-height: 1.2em;
  opacity: 0.9;
  margin-top: 60px;
  span {
    background: linear-gradient(to right, #ffbd3d, #ff9500);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: 1274px) {
    font-size: 16px;
    margin-top: 20px;
  }
`;

const MenuBox = styled.ul`
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 50px;
  gap: 30px;
  a {
    color: #fff;
  }
  li {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    font-family: Roboto-Regular;
    cursor: pointer;
    &.active,
    &:hover {
      color: #ff9500;
      &:after {
        content: "";
        width: 20px;
        height: 2px;
        background: #ff9500;
        position: absolute;
        left: 0;
        bottom: -8px;
      }
    }
  }
  @media (max-width: 1274px) {
    display: none;
  }
`;

const RightLayout = ({ children }) => {
  const [show, setShow] = useState(false);
  const [showDown, setShowDown] = useState(false);
  const [showMenu, setshowMenu] = useState(false);
  const account = useSelector((store) => store.account);

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

  useEffect(() => {
    document.addEventListener("click", (e) => {
      setShowDown(false);
      setshowMenu(false);
    });
  }, []);

  const handleClose = () => {
    setShow(false);
  };
  const handleCloseMenu = () => {
    setshowMenu(false);
  };

  const connectWallet = () => {
    setShow(true);
  };

  const Disconnect = () => {
    store.dispatch(saveAccount(null));
    store.dispatch(saveConnectData(null));
    store.dispatch(saveJoyidInfo(null));
    setShowDown(false);
    setshowMenu(false);
  };

  const handleDown = (e) => {
    if (isMobile) {
      setshowMenu(true);
      e.stopPropagation();
    } else {
      setShowDown(true);
      e.stopPropagation();
    }
  };

  return (
    <div className="flex flex-1 flex-col h-full  text-white">
      {show && <ConnectModal show={show} handleClose={handleClose} />}
      {showMenu && (
        <div className="menuMobile">
          <ul>
            <X onClick={() => handleCloseMenu()} className="mb-4" />
            {/*<a href="https://app.dobby.market/" rel="noreferrer">*/}
            {/*    <li>Marketplace</li>*/}
            {/*</a>*/}
            {/*<a href="https://creator.dobby.market/" rel="noreferrer">*/}
            {/*    <li>Creator</li>*/}
            {/*</a>*/}
            <li className="active">Accelerator</li>
          </ul>
          <div className="disconnect" onClick={() => Disconnect()}>
            <Link2Off size={18} className="text-primary011" />{" "}
            <span>Disconnect</span>
          </div>
        </div>
      )}
      <div className="topBg">
        <div className="inner">
          <LogoBox>
            <img src={LogoImg} alt="" />
          </LogoBox>

          <MenuBox>
            {/*<a href="https://app.dobby.market/" rel="noreferrer">*/}
            {/*        <li>Marketplace</li>*/}
            {/*    </a>*/}
            {/*    <a href="https://creator.dobby.market/" rel="noreferrer">*/}
            {/*        <li>Creator</li>*/}
            {/*    </a>*/}

            <li className="active">Accelerator</li>
          </MenuBox>
          <div className="flex flex-1 justify-end align-middle items-end relative">
            {!account && (
              <>
                <button className="btnPrimary" onClick={() => connectWallet()}>
                  <Wallet size={18} />
                  Connect Wallet
                </button>
              </>
            )}
            {!!account && (
              <RhtBox onClick={(e) => handleDown(e)}>
                {" "}
                <img src={BtcImg} alt="" />
                {shortAddress(account, 5)}
              </RhtBox>
            )}

            {showDown && (
              <div
                className="absolute right-0 btm20 z-10 mt-2 w-36 origin-top-right rounded-md bgBack2 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in flex items-center gap-3 py-3 px-2"
                onClick={() => Disconnect()}
              >
                <Link2Off size={16} className="text-primary011" />
                <span>Disconnect</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <ContentBox>
        <div>
          <TitleBox>
            RGB++ Transaction <span>Accelerator</span>
          </TitleBox>
          {!!account && <Balance />}
        </div>

        <div className="flex-1 overflow-auto h-full">{children}</div>
      </ContentBox>
      <Footer></Footer>
    </div>
  );
};
export default RightLayout;
