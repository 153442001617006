import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TOKEN_CONFIG } from "../utils/const.js";

import { BookDashed } from "lucide-react";
import { BtcAssetsApi } from "@rgbpp-sdk/service";
import TransactionItem from "../components/TransactionItem.jsx";
import Loading from "../components/loading.jsx";
import { RgbppHelper } from "dobs-dex-sdk";
import store from "../store/index.js";
import { saveLoading } from "../store/reducer.js";

export default function Home() {
  const account = useSelector((store) => store.account);
  const loading = useSelector((store) => store.loading);

  const [list, setList] = useState([]);

  useEffect(() => {
    if (!account) return;
    getList();
  }, [account]);

  const getList = async () => {
    store.dispatch(saveLoading(true));
    try {
      const isMainnet = import.meta.env.VITE_CHAIN === "livenet";
      //
      const BTC_ASSETS_API_URL =
        TOKEN_CONFIG[import.meta.env.VITE_MODE].BTC_ASSETS_API_URL;
      const BTC_ASSETS_ORGIN =
        TOKEN_CONFIG[import.meta.env.VITE_MODE].BTC_ASSETS_ORGIN;
      const BTC_ASSETS_TOKEN =
        TOKEN_CONFIG[import.meta.env.VITE_MODE].BTC_ASSETS_TOKEN;
      const rgbppHelper = new RgbppHelper(
        isMainnet,
        BTC_ASSETS_API_URL,
        BTC_ASSETS_TOKEN,
        BTC_ASSETS_ORGIN,
      );

      const result = await rgbppHelper.getUnconfirmedTransaction(account);
      setList(result);

      // const service = BtcAssetsApi.fromToken(
      //     BTC_ASSETS_API_URL,
      //     BTC_ASSETS_TOKEN,
      //     BTC_ASSETS_ORGIN
      // );
      //
      // const aaa = await service.getRgbppTransactionHash("4c9e3e48af572886c19c97e8716a40637272a1c9559364cec09d5166bd873c82")
      // const result = await service.getBtcTransactions(account)
      // setList(result)
      // console.log(result)
    } catch (e) {
      console.error(e);
    } finally {
      store.dispatch(saveLoading(false));
    }
  };

  return (
    <div className="w-full h-full flex flex-1 min-h-0 no-scrollbar ">
      <div className="flex-1 h-full sm:pr-0 sm:border-none overflow-scroll no-scrollbar relative">
        {(loading || !list.length) && (
          <div className="w-full h-full flex flex-col items-center justify-center gap-8">
            <div className="opacity-30 uppercase flex-1 h-full w-full flex items-center justify-center">
              {!loading && !list.length && (
                <div className="flex gap-4">
                  <BookDashed />
                  No pending transactions
                </div>
              )}
            </div>
          </div>
        )}
        {loading && <Loading />}

        {!!list.length && (
          <div className="pb-10 flex-1 pr-4 ">
            <div className="top-0 font-medium text-sm py-4 sm:py-0">
              <div className="px-4 text-sm text-subdued mb-2 capitalize pt-4"></div>
              {list.map((transaction) => (
                <TransactionItem
                  key={transaction.txid}
                  transaction={transaction}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
