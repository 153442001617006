import {
  ArrowBigRight,
  ArrowBigDown,
  CircleFadingArrowUp,
  ExternalLink,
} from "lucide-react";

import { useEffect, useState } from "react";
import { BitcoinUnit } from "bitcoin-units";
import { useSelector } from "react-redux";
import RGB from "./rgb.jsx";
import ModalTx from "./modalTx.jsx";
import { shortAddress } from "../utils/global.js";
import { getStatus } from "../api/btc.js";

import { getSporeScript, predefinedSporeConfigs } from "@spore-sdk/core";

export default function TransactionItem({ transaction }) {
  const account = useSelector((store) => store.account);
  const symbol = import.meta.env.VITE_CHAIN === "testnet" ? "tBTC" : "BTC";
  const [feeRate, setFeeRate] = useState(0);
  const [show, setShow] = useState(false);
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
  const [canAccelerate, setCanAccelerate] = useState(false);

  useEffect(() => {
    let rt = (transaction?.fee / transaction?.size).toFixed(2);
    setFeeRate(rt);

    returnFeeRate(transaction);
    checkStatus();
  }, [transaction]);
  const formatBTC = (num) => {
    return new BitcoinUnit(num ?? 0, "sats").to("BTC").toString();
  };

  const formatTx = (str) => {
    if (isMobile) {
      return shortAddress(str);
    } else {
      return str;
    }
  };

  const checkStatus = async () => {
    let result = false;

    if (transaction?.vin?.length === 1) {
      result = true;
    } else {
      const firstAddress = transaction?.vin[0].prevout.scriptpubkey_address;
      result = transaction?.vin?.every(
        (item) => item.prevout.scriptpubkey_address === firstAddress,
      );

      if (!result) {
        const item = transaction?.vin.find(
          (item) => item.prevout.value === 546,
        );
        let sporeArr = await getStatus(item?.txid, item?.vout);

        const version =
          import.meta.env.VITE_CHAIN === "testnet" ? "preview" : "latest";
        const sporeConfig =
          import.meta.env.VITE_CHAIN === "testnet"
            ? predefinedSporeConfigs.Testnet
            : predefinedSporeConfigs.Mainnet;
        const sporeTypeScript = getSporeScript(sporeConfig, "Spore", [
          "v2",
          version,
        ]);
        const didScript = getSporeScript(sporeConfig, "Spore", ["v2", "did"]);

        if (
          sporeArr[0]?.cellOutput?.type?.codeHash ===
            sporeTypeScript.script.codeHash ||
          sporeArr[0]?.cellOutput?.type?.codeHash === didScript.script.codeHash
        ) {
          result = true;
        }
      }
    }
    setCanAccelerate(result);
  };

  const returnAddr = (item) => {
    if (item.scriptpubkey_type === "op_return") {
      return <span className="uppercase">{item.scriptpubkey_type}</span>;
    } else {
      let newAddr = item.scriptpubkey_address;

      let dec = isMobile ? 8 : 18;

      return (
        <span className={newAddr === account ? "text-green-600" : ""}>
          {shortAddress(newAddr, dec)}
        </span>
      );
    }
  };

  const returnFeeRate = (item) => {
    const { weight, fee } = item;

    setFeeRate((fee / (weight / 4)).toFixed(0));
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  return (
    <>
      {show && <ModalTx txHash={transaction.txid} handleClose={handleClose} />}

      <div className="p-4 sm:border-b last:border-none  rounded-lg mb-4 ml-4  bgwhiteOp">
        <div className="flex items-center  border-b border-gray-600 pb-4">
          <div className="relative rounded-full flex h-8 items-center justify-center">
            <CircleFadingArrowUp className="text-primary011" />
          </div>
          <div className="overflow-y-visible overflow-x-auto sm:overflow-x-visible whitespace-nowrap text-ellipsis flex flex-wrap flex-1 justify-between lg:grid grid-cols-12 gap-4 lg:gap-5 xl:gap-6 items-center">
            <div className="flex items-center space-x-4 col-span-4 2xl:col-span-3">
              <div className="flex items-center justify-end space-x-2 col-span-1 2xl:col-span-2">
                <div className="ml-4  text-slate-300 whitespace-nowrap">
                  {formatTx(transaction.txid)}
                </div>
                <a
                  href={`https://mempool.space/${import.meta.env.VITE_CHAIN === "testnet" && "testnet"}/tx/${transaction.txid}`}
                  className="text-white hover:bg-primary011 hover:text-white p-2 rounded-full flex cursor-pointer"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ExternalLink size={16} />
                </a>
              </div>
            </div>
          </div>
          <div className="sm:flex-1 lg:flex truncate ">
            <RGB txHash={transaction.txid} />
          </div>
        </div>
        <div className="flex justify-between pt-4 items-start sm:flex-col flex-1r">
          <div className=" flex-1 bgOrange px-2 py-1 sm:w-full ">
            {transaction?.vin.map((item, index) => (
              <div className="flex justify-between py-2" key={index}>
                <div>{returnAddr(item.prevout)}</div>
                <div>
                  <span className="text-primary011">
                    {formatBTC(item?.prevout?.value)}
                  </span>{" "}
                  {symbol}
                </div>
              </div>
            ))}
          </div>
          <div className="px-8 py-2 sm:w-full sm:flex sm:justify-center">
            <ArrowBigRight className="sm:hidden " />
            <ArrowBigDown className="md:hidden lg:hidden" />
          </div>

          <div className=" flex-1 bgOrange px-2 py-1 sm:w-full ">
            {transaction?.vout.map((item, index) => (
              <div className="flex justify-between py-2" key={index}>
                <div>{returnAddr(item)}</div>
                <div>
                  <span className="text-primary011">
                    {formatBTC(item?.value)}
                  </span>{" "}
                  {symbol}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-between items-end mt-2">
          <div className="text-xs">
            <span>{feeRate} sat/vB</span> - <span>{transaction.fee} sat</span>
          </div>
          <button
            disabled={!canAccelerate}
            onClick={() => handleShow()}
            className="btnPrimary acc capitalize"
          >
            {!canAccelerate && <span className="line-through">RBF</span>}
            {canAccelerate && <span>accelerate</span>}
          </button>
        </div>
      </div>
    </>
  );
}
