import styled from "styled-components";
// import {Input, Button, notification} from "antd";
import { useEffect, useState } from "react";
import { assetsInfoByHash, getFeeRate, getPsbt, sendRbfToSendServer } from "../api/btc.js";
import store from "../store/index.js";
import { saveLoading } from "../store/reducer.js";
import { useSelector } from "react-redux";
import { TOKEN_CONFIG } from "../utils/const.js";
import { bitcoin, NetworkType, networkTypeToConfig } from "@rgbpp-sdk/btc";
import { RgbppHelper } from "dobs-dex-sdk";
import { handlePublicKey, handleSignPsbt } from "../utils/wallet.js";
import { enqueueSnackbar } from "notistack";

import { X } from "lucide-react"

import { BtcAssetsApi } from "@rgbpp-sdk/service";

const Modal = styled.div`
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    z-index:9;
    display: flex;
    align-items: center;
    justify-content: center;
    .nft{
        width: 80px;
        height: 80px;
        border-radius: 10px;
        object-fit: cover;
        object-position: center;
    }
    .usdt{
        margin-left: 34px;
        color: #ff9900;
    }
    .bgGrayBk{
        background: rgb(25, 26, 30) ;
    }
`


export default function ModalTx({ handleClose, txHash }) {

    const [feeRate, setFeeRate] = useState(0);
    const account = useSelector(store => store.account);
    const type = useSelector(store => store.type)


    useEffect(() => {
        getRate()
    }, [])



    const getRate = async () => {
        store.dispatch(saveLoading(true));
        try {
            let rt = await getFeeRate();
            const { fastestFee } = rt;

            setFeeRate(fastestFee)
        } catch (e) {
            console.error("Get Fee Rate", e)
            enqueueSnackbar("Get Fee Rate Error" + e?.response?.data?.message || e.message || e.reason, { variant: "error" })

        }
        finally {
            store.dispatch(saveLoading(null));
        }
    }

    const transfer = async () => {
        store.dispatch(saveLoading(true))

        try {
            const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

            let publicKey = await handlePublicKey(type, isMobile)

            let result = await handleConfirm(txHash, account, publicKey, feeRate)

            console.log("result", result)
            enqueueSnackbar(`Success:${result}`, { variant: "success" })


        } catch (e) {
            console.error(e)
            enqueueSnackbar(e?.response?.data?.message || e.message || e.reason, { variant: "error" })

        } finally {
            store.dispatch(saveLoading(false))
        }

    }


    const handleConfirm = async (tx_hash, fromBtcAddress, fromBtcPubkey, fee_rate) => {
        store.dispatch(saveLoading(true));
        try {

            const isMainnet = import.meta.env.VITE_CHAIN === "livenet"
            const BTC_ASSETS_API_URL = TOKEN_CONFIG[import.meta.env.VITE_MODE].BTC_ASSETS_API_URL;
            const BTC_ASSETS_ORGIN = TOKEN_CONFIG[import.meta.env.VITE_MODE].BTC_ASSETS_ORGIN;
            const BTC_ASSETS_TOKEN = TOKEN_CONFIG[import.meta.env.VITE_MODE].BTC_ASSETS_TOKEN;

            const networkType = import.meta.env.VITE_CHAIN === "testnet" ? NetworkType.TESTNET : NetworkType.MAINNET;

            const cfg = networkTypeToConfig(networkType);

            console.log("cfg", cfg)

            const rgbppHelper = new RgbppHelper(isMainnet, BTC_ASSETS_API_URL, BTC_ASSETS_TOKEN, BTC_ASSETS_ORGIN);


            const { btcTranscation, psbt, ckbVirtualTxResult } = await rgbppHelper.buildRbfTransferPsbt(tx_hash, fromBtcAddress, fromBtcPubkey, fee_rate); // feeRate

            let flagIsOrderRbf = false;
            let txHash = '';
            let txIdx = -1;
            for (let i = 0; i < btcTranscation.vin.length; i++) {
                const element = btcTranscation.vin[i];
                if (element.prevout.scriptpubkey_address !== fromBtcAddress && element.prevout.value <= cfg.rgbppUtxoDustLimit) {
                    flagIsOrderRbf = true;
                    txHash = element.txid;
                    txIdx = element.vout;
                    break;
                }
            }

            if (flagIsOrderRbf) {
                const btcService = BtcAssetsApi.fromToken(BTC_ASSETS_API_URL, BTC_ASSETS_TOKEN, BTC_ASSETS_ORGIN);

                const rgbppAsset = await btcService.getRgbppAssetsByBtcUtxo(txHash, txIdx);

                const assetHashId = rgbppAsset[0].cellOutput.type.args.indexOf('0x') === 0 ? rgbppAsset[0].cellOutput.type.args.substring(2) : rgbppAsset[0].cellOutput.type.args
                const assetInfo = await assetsInfoByHash(assetHashId);

                // console.log(assetInfo);

                if (assetInfo.order) {
                    if (assetInfo.from_address === account) {
                        throw new Error('You can not replace this transaction');
                    }

                    const rt = await getPsbt(assetInfo.order.order_id);
                    console.log("~~~~~~handlePBST", rt)

                    const { psbt: psbtHex } = rt;

                    // check tapKeySig & partialSig in input[1]
                    let checkSign = bitcoin.Psbt.fromHex(psbtHex, { network: cfg.network })

                    console.log("checkSign", checkSign)

                    psbt.data.inputs[1] = checkSign.data.inputs[1];
                }
            }

            const signInputList = [];

            for (let i = 0; i < psbt.txInputs.length; i++) {
                if (!psbt.data.inputs[i].tapKeySig && !psbt.data.inputs[i].partialSig) {
                    signInputList.push({
                        index: i,
                        address: account,
                        publicKey: fromBtcPubkey,
                    });
                }
            }

            console.log(psbt)

            let psbtSigned;

            psbtSigned = await handleSignPsbt(
                psbt.toHex(),
                type,
                {
                    autoFinalized: false,
                    toSignInputs: signInputList,
                }
            );

            console.log(psbt.toHex())



            //
            const newPsbt = bitcoin.Psbt.fromHex(psbtSigned, { network: cfg.network });
            // const rawTx = bitcoin.Transaction.fromHex(psbt.toHex(), { network: cfg.network });
            //
            newPsbt.finalizeAllInputs();
            const btcTx = newPsbt.extractTransaction();
            //
            console.log("btcTx", btcTx.toHex())

            const rt = await sendRbfToSendServer(btcTx.toHex(), ckbVirtualTxResult)

            console.log('------rbf tx result', rt);
            return rt
        } catch (e) {
            console.error(e)
            enqueueSnackbar(e?.response?.data?.message || e.message || e.reason, { variant: "error" })
        } finally {
            store.dispatch(saveLoading(false));
        }

    }
    return <Modal>
        <div className="bgGrayBk rounded-lg shadow-lg p-4 w-[420px] relative sm:w-11/12">
            <div className="font-din justify-center flex text-xl mb-4">Accelerate</div>
            <div className="absolute right-3 top-4 cursor-pointer" onClick={() => handleClose()}><X size={18}/></div>
            <div className="flex flex-col mb-4" >
                <div className="font-semibold mb-1">Transaction</div>
                <div className="w-full">
                    <textarea className="w-full h-16 px-3 py-3 text-sm rounded-md text-white outline-none focus:ring-2 focus:ring-primary-default bg-black resize-none " value={txHash} readOnly />
                </div>

            </div>
            <div className="flex flex-col mb-4" >
                <div className="font-semibold mb-1">Fee Rate <span className="text-slate-400 text-xs">(sat/vB)</span></div>
                <input className="w-full h-11 px-3 text-sm rounded-md text-white outline-none focus:ring-2 focus:ring-primary-default bg-black " value={feeRate} onChange={(e) => setFeeRate(Number.parseInt(e.target.value))} />
            </div>
            <button className="btnPrimary btnTransfer" disabled={!txHash || !feeRate} onClick={() => transfer()}>
                Accelerate
            </button>
        </div>
    </Modal>

}
