import styled from "styled-components";
import {useEffect, useState} from "react";
import LoadingBtn from "./loadingBtn.jsx";
import {BtcAssetsApi} from "@rgbpp-sdk/service";
import {TOKEN_CONFIG} from "../utils/const.js";
import store from "../store/index.js";
import {saveLoading} from "../store/reducer.js";
import {useSelector} from "react-redux";

const TagBox = styled.div`
    align-items: center;
    background: linear-gradient(90.24deg, #ffd176 .23%, #ffdb81 6.7%, #84ffcb 99.82%);
    border-radius: 4px;
    color: #333;
    display: flex;
    font-size: 12px;
    height: 22px;
    justify-content: center;
    line-height: 14px;
    padding: 4px;
    vertical-align: middle;
`

export default function RGB({txHash}){
    const [show, setShow] = useState(false);
    const loading = useSelector(store => store.loading);

    useEffect(() => {
        getDetail()
    }, [txHash]);

    const getDetail = async() =>{
        store.dispatch(saveLoading(true));
        const BTC_ASSETS_API_URL = TOKEN_CONFIG[import.meta.env.VITE_MODE].BTC_ASSETS_API_URL;
        const BTC_ASSETS_ORGIN = TOKEN_CONFIG[import.meta.env.VITE_MODE].BTC_ASSETS_ORGIN;
        const BTC_ASSETS_TOKEN = TOKEN_CONFIG[import.meta.env.VITE_MODE].BTC_ASSETS_TOKEN;

        const service = BtcAssetsApi.fromToken(
            BTC_ASSETS_API_URL,
            BTC_ASSETS_TOKEN,
            BTC_ASSETS_ORGIN
        );
        try{

            const result = await service.getRgbppTransactionHash(txHash)
            setShow(true)
        }catch (e){
            console.log(e)
            setShow(false)
        }finally {
            store.dispatch(saveLoading(false));
        }

    }

    return <>

        {
            show && !loading &&  <TagBox>
                RGB++: Isomorphic
            </TagBox>
        }
        {
            !show  && loading && <LoadingBtn />
        }

    </>
}
