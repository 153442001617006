export const SIG_WORD = "Welcome to RGB++ Tx Accelerator powered by Haste";

export const TOKEN_CONFIG = {
  dev: {
    BTC_ASSETS_API_URL: "https://rgbppio.entrust3.com/testnet",
    BTC_ASSETS_TOKEN:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJzb3VsLXRlc3QtYXBwIiwiYXVkIjoibG9jYWxob3N0IiwiaWF0IjoxNzExNTM0OTMxfQ.NAhr_3Aro90wLwKOYvnjMme_YslZspRmf5GzBvxw3FU",
    BTC_ASSETS_ORGIN: "localhost",
  },
  prod: {
    BTC_ASSETS_API_URL: "https://rgbppio.entrust3.com/mainnet",
    BTC_ASSETS_TOKEN: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJET0JCWSBBUFAiLCJhdWQiOiJhcHAuZG9iYnkubWFya2V0IiwianRpIjoiYzIxZmNhMTYtZWQxMC00MzFkLThlMGUtNjcyYjE4MmU0NDhiIiwiaWF0IjoxNzE0MDMwMTMzfQ.wZnftclz4zsANWqzQPMqkCfDFrr5VH-iaZFQ_9CDnbc",
    BTC_ASSETS_ORGIN: "app.dobby.market",
  },
};

export const BASE_URL = {
  dev: "https://damp.entrust3.com/testnet",
  prod: "https://damp.entrust3.com/mainnet",
};
