import styled from "styled-components";

import Unisat from "./wallet/unisat.jsx";
import OKX from "./wallet/okx.jsx";
import Joyid from "./wallet/joyid.jsx";
import JoyidSEG from "./wallet/joyidSEG.jsx";
import {X} from "lucide-react"


const Modal = styled.div`
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    position: fixed;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(5px) ;
    -webkit-backdrop-filter: blur(5px);
    z-index:99999;
    display: flex;
    align-items: center;
    justify-content: center;
    .bgBack{
    background: rgb(25, 26, 30) ; }
`


const Box = styled.div`
    width: 100%;
    padding: 15px 0 0;
    dl{
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 10px;
        background: #000;
        padding: 0.5rem ;
        border-radius: 0.25rem;
        cursor: pointer;


    }
    img{
        width: 30px;
        border-radius: 50px;
        border: 1px solid rgba(255,255,255,0.3);
        margin-left:80px;
    }
    dd{
        font-size: 18px;
        color: #fff;
        font-family: Roboto-Regular;
    }
    
`

export default function ConnectModal({show,handleClose}) {

    return   <Modal
        onClick={() => handleClose()}
    >
        <div className=" rounded-lg shadow-lg z-10  bg-opacity-80  flex flex-col  gap-4 pb-5 w-[420px] py-4 px-4 sm:w-11/12 bgBack relative">
            <div className="font-din justify-center flex text-xl">Connect wallet</div>
            <X size={18} className="absolute right-4 top-4  cursor-pointer" />
            <Box>
                <Unisat handleClose={handleClose}/>
                <OKX handleClose={handleClose}/>
                <Joyid handleClose={handleClose}/>
                <JoyidSEG handleClose={handleClose}/>
            </Box>
        </div>

    </Modal>
}
