import OkxImg from "../../assets/okx.png";
import {useEffect, useRef, useState} from "react";
import store from "../../store";
import {saveAccount, saveLoading, saveType} from "../../store/reducer";
import {enqueueSnackbar} from "notistack";
// import {App} from "antd";
export default function OKX({handleClose}){
    // const {  notification } = App.useApp();
    const [disable,setdisable] = useState(false);

    const {okxwallet} = window;

    const selfRef = useRef ({
        accounts: [],
    });
    const self = selfRef.current;

    // useLogin(handleClose,"okx");

    useEffect(() => {
        const checkOKX = async () => {
            if (!okxwallet) {
                setdisable(true)
                return;
            }
            setdisable(false)
        }
        checkOKX();
    }, []);


    const connect = async() =>{

        store.dispatch(saveLoading(true));


        try{
            if(disable){
                throw new Error("Okx Wallet is not installed!")
            }
            let result
            if(import.meta.env.VITE_CHAIN==="testnet") {
                result = await okxwallet?.bitcoinTestnet.connect();
            }else{
                result = await okxwallet.bitcoin.connect();
            }

            self.accounts = result.address;
            if (result.address) {
                store.dispatch(saveAccount(result.address));
                store.dispatch(saveType('okx'));

                handleClose()
            }
        }catch (e) {
            console.error(e?.response?.data?.message || e.message || e.reason || e)
            enqueueSnackbar( (e?.response?.data?.message || e.message || e.reason || e), {variant: "error"})

        }finally {
            store.dispatch(saveLoading(false));
        }


    }


    return <dl onClick={()=>connect()}>
        <dt>
            <img src={OkxImg} alt=""/>
        </dt>
        <dd  className='text-black font-Montserrat'>OKX Wallet</dd>
    </dl>
}
