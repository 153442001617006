import store from "../store/index.js";
import {saveUtxo} from "../store/reducer.js";

export const shortAddress = (addr,num=7) => {
    if(!addr) return "";
    const address  = addr?.trim().toString();
    const frontStr = address?.substring(0, num);

    const afterStr = address?.substring(address.length - num, address.length);

    return `${frontStr}...${afterStr}`;
};



export const  toJSONStr = (str,keyName) => {
    if (typeof str !== 'string') {

        return  keyName?str[keyName]:str;
    }

    try {
        let rt = JSON.parse(str);
        if(typeof rt !== "string"){
            return keyName?rt[keyName]:rt
        }else{
            return rt;
        }

    } catch (e) {
        return str;
    }
}


