import request from "./index.js";
import { BASE_URL, TOKEN_CONFIG } from "../utils/const.js";
import superagent from "superagent";
import { BtcAssetsApi } from "@rgbpp-sdk/service";

const rootPrefix = `${BASE_URL[import.meta.env.VITE_MODE]}/${import.meta.env.VITE_CHAIN === "testnet" ? "/btc" : ""}`;

export const getFeeRate = () => {
  return request.get(`https://mempool.space/api/v1/fees/recommended`);
};

export const assetsInfoByHash = (hash) => {
  return request.get(`${rootPrefix}/assets/asset/${hash}`);
};

export const getPsbt = (id) => {
  return request.post(`${rootPrefix}/orders/${id}/get_psbt`);
};

export const sendRbfToSendServer = (tx, ckbVirtualTxResult) => {
  return request.post(`${rootPrefix}/assets/rbftx`, {
    raw_tx: tx,
    ckb_virtual_tx_result: ckbVirtualTxResult,
  });
};

async function fetchFromMempoolSpace(endpoint) {
  try {
    const url = `https://mempool.space${import.meta.env.VITE_CHAIN === "livenet" ? "" : "/testnet"}/${endpoint}`;
    const result = await superagent.get(url);
    if (result.status === 200) {
      return JSON.parse(result.text);
    }
  } catch (err) {
    throw err;
  }
}

const getBTC = async (address) => {
  return await fetchFromMempoolSpace(`api/address/${address}`);
};

export const getBTCAsset = async (address) => {
  let result = await getBTC(address);
  return result;
};

export const getStatus = async (txId, vout) => {
  const BTC_ASSETS_API_URL =
    TOKEN_CONFIG[import.meta.env.VITE_MODE].BTC_ASSETS_API_URL;
  const BTC_ASSETS_ORGIN =
    TOKEN_CONFIG[import.meta.env.VITE_MODE].BTC_ASSETS_ORGIN;
  const BTC_ASSETS_TOKEN =
    TOKEN_CONFIG[import.meta.env.VITE_MODE].BTC_ASSETS_TOKEN;

  const service = BtcAssetsApi.fromToken(
    BTC_ASSETS_API_URL,
    BTC_ASSETS_TOKEN,
    BTC_ASSETS_ORGIN,
  );

  return await service.getRgbppAssetsByBtcUtxo(txId, vout);
};
