export default {
  account: null,
  connectData:null,
  type: null,
  loading: null,
  showSign:null,
  joyidInfo:null,
  utxo:[],
  didHash:null,
};
