import store, { persistor } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import GlobalStyle from "./utils/GlobalStyle";
import Routerlink from "./router/router";
import { HashRouter as Router } from "react-router-dom";
import { initConfig } from "@joyid/bitcoin";

import { MaterialDesignContent, SnackbarProvider } from "notistack";

import * as Sentry from "@sentry/react";

if (import.meta.env.VITE_CHAIN === "livenet") {
  Sentry.init({
    dsn: "https://4bfd0a67f38c829f2e3fbcc9d9e6d5ca@o4507175892353024.ingest.us.sentry.io/4507972891574272",
    integrations: [Sentry.replayIntegration()],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

function App() {
  let joyidType = localStorage.getItem("joyidType");

  initConfig({
    name: "RGB++ Tx Accelerator",
    logo: `${window.location.origin}/images/newlogo.png`,
    requestAddressType: joyidType ?? "p2tr",
    joyidAppURL:
      import.meta.env.VITE_CHAIN === "testnet"
        ? "https://testnet.joyid.dev"
        : "https://app.joy.id",
  });

  return (
    <Provider store={store}>
      <SnackbarProvider
        autoHideDuration={3000}
        Components={{
          success: MaterialDesignContent,
          error: MaterialDesignContent,
        }}
      >
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Routerlink />
            <GlobalStyle />
          </Router>
        </PersistGate>
      </SnackbarProvider>
    </Provider>
  );
}

export default App;
